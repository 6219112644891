import React from 'react';
import { useQuery } from '@apollo/client';
import { Container, Row, Col } from "react-bootstrap"
import gql from "graphql-tag"
import Helmet from 'react-helmet'

import SEO from "../components/seo"
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import ErrorPage from '../pages/PropertyDetails404'

import TopBar from "../components/PropertyDetails/TopBar/TopBar";
import Banner from "../components/PropertyDetails/DetailsBanner/NewHomesBanner";
import ViewPage from "../components/PropertyDetails/ViewPage/ViewPageNewHomes";
// import LoadExternalScript from "../components/utils/load-external-script";
// import Description from "../components/PropertyDetails/Description/Description";
// import RoomDetails from "../components/PropertyDetails/RoomDetails/RoomDetails";
// import Owner from "../components/PropertyDetails/Owner/Owner";
// import Payment from "../components/PropertyDetails/Payment/Payment";
// import Info from "../components/PropertyDetails/Info/Info";
// import SimilarProperty from "../components/PropertyDetails/Property/SimilarProperty";
import PropertyStructuredData from "../components/Seo/PropertyStructuredDataNewHomes"
import logoBlack from "../images/logo-dark.svg";
import { navigate } from "@reach/router"
import { graphql } from 'gatsby'


const PropertyDetailsTemplate = (props) => {

  // 
  const pageurl = typeof window !== 'undefined' ? window.location.href : ''
  let myid_filt = pageurl.split('?');
  let mypageurl = myid_filt[0];
  let property_slug = mypageurl.split('-');
  let id = property_slug[property_slug.length - 1];
  id = id.replace("/", "");


  const newDevelopmentsArray = props.data.newDevelopments 
    ? Array.isArray(props.data.newDevelopments) 
      ? props.data.newDevelopments 
      : [props.data.newDevelopments] 
    : [];
  return (
    <React.Fragment>
      {newDevelopmentsArray && newDevelopmentsArray.length > 0 ?
        <div className="wrapper property-details-wrapper new-homes">
          <Helmet
            bodyAttributes={{
              class: 'property-details-page-new-homes'
            }}
          />
          <Header />
          {newDevelopmentsArray && newDevelopmentsArray.length > 0 ? newDevelopmentsArray.map((data, i) => {
            const metaTitle = `New homes for sale with ${data.bedrooms.toString()} bedrooms in ${data.development_title} at ${data.price_prefix} £${data.min_price.toLocaleString()} - £${data.max_price.toLocaleString()} | Wards`
            const metaDesc = `Find the details of New homes for sale with ${data.bedrooms.toString()} bedrooms in ${data.development_title} at ${data.price_prefix} £${data.min_price.toLocaleString()} - £${data.max_price.toLocaleString()} with Wards. Request a viewing to get assistance in buying this new build homes.`
            return (
              <>
                <PropertyStructuredData
                  property={data}
                  pageurl={pageurl}
                />
                <SEO title={metaTitle} description={metaDesc} />
                <TopBar {...data} search_type="new_development"/>
                <Banner propertyData={data} />
                <ViewPage propertyData={data} />
                {/* <SimilarProperty propertyData={data} /> */}
              </>
            )
          }) : ''}
          <Footer />
        </div> : <ErrorPage />}
    </React.Fragment>
  )
}

export default PropertyDetailsTemplate;
export const pageQuery = graphql`
  query ($id: String!) {
    newDevelopments(id: { eq: $id }) {
      id
      development_id
      development_title
      min_price
      max_price
      price_prefix
      latitude
      longitude
      short_description
      long_description
      office_crm_id
      sub_heading_2
      images {
        caption
        srcUrl
        url
        versionNumber
        feedURL
        updatedAt
        type
        sequence
        last_modified
        createdAt
        etag
      }
      imagetransforms {
        images_Transforms
        Images_Transforms
      }  
      bedrooms
      banner_video
      brochure {
        srcUrl
        feedURL
        sequence
        type
        caption
      }
      telephone
    }
  }
`;
